import type { News } from '@/types';
import type { SwiperOptions } from 'swiper/types';

import { FreeMode } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';

import { BlogPostCard, Box, Button, Link, Text } from '@/components';
import { useTranslation } from '@/hooks';
import { routes } from '@/statics';

const BlogMobile: React.FC<{
  blogs: News[];
}> = ({ blogs }) => {
  const { t } = useTranslation('pages.home');

  const settings: SwiperOptions = {
    freeMode: true,
    slidesPerView: 1.5,
    breakpoints: {
      576: {
        slidesPerView: 2.5,
      },
    },
  };
  return (
    <Box
      sx={{
        '.swiper': {
          padding: '10px',
          marginBottom: '24px',
        },
        '.swiper-slide': {
          width: 'calc(100% / 1.5)',
          paddingInline: '10px',
          '@media screen and (min-width: 576px)': {
            width: 'calc(100% / 2.5)',
          },
        },
      }}
    >
      <Text fontSize="sm" fontWeight="bold" textAlign="center" mb={8}>
        {t('followTheLatestNewsMobile')}
      </Text>
      <Swiper
        watchSlidesProgress={true}
        dir="rtl"
        {...settings}
        modules={[FreeMode]}
      >
        {blogs?.map((blog) => (
          <SwiperSlide key={blog.id} style={{ height: 'auto' }}>
            <BlogPostCard
              image={blog.preview_image}
              title={blog.title}
              dateOfRelease={blog.publish_at}
              studyTime={blog.study_time}
              sizes="33vw"
              loading="eager"
              code={blog.code}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <Box display="flex" justifyContent="center">
        <Button
          variant="outline"
          colorScheme="gray"
          as={Link}
          href={routes.blogs.url}
        >
          {t('viewAllBlogs')}
        </Button>
      </Box>
    </Box>
  );
};
export default BlogMobile;
