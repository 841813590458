import type { ElementType } from 'react';

import { Icon, Tab } from '@/components';

const TabItem: React.FC<{ title: string; icon?: ElementType }> = ({
  title,
  icon,
}) => {
  return (
    <Tab
      _selected={{
        color: 'primary.700',
        '& svg': { visibility: { base: 'hidden', md: 'visible' } },
      }}
      fontSize={{ base: 'sm', md: 'xl' }}
      color="text"
    >
      <Icon
        display={{ base: 'none', md: 'inline-block' }}
        visibility={'hidden'}
        as={icon}
        style={{
          marginLeft: '11px',
          width: 24,
          height: 24,
          stroke: 'primary.700',
        }}
      />
      {title}
    </Tab>
  );
};
export default TabItem;
