'use client';
import React from 'react';
import Countdown from 'react-countdown';

import { Box, HStack, Text } from '@/components';
import { useTranslation } from '@/hooks';

type IFlashSaleProps = {
  endDate: string;
};
const FlashSale: React.FC<IFlashSaleProps> = ({ endDate }) => {
  const { t } = useTranslation('pages.home');
  const [isCompleted, setIsCompleted] = React.useState(false);

  const flashSaleDate = new Date(endDate);

  if (Date.now() > flashSaleDate.getTime() || isCompleted) {
    return null;
  }

  return (
    <HStack
      justify="center"
      gap={1}
      zIndex="docked"
      bg="primary.500"
      color="white"
      borderRadius="md"
      px={2}
      maxW="fit-content"
    >
      <Text color="white">{t('tillEnd')}</Text>
      <Box fontWeight="bold" minW="80px" textAlign="end">
        <Countdown
          date={flashSaleDate}
          daysInHours
          overtime={false}
          onComplete={() => setIsCompleted(true)}
        />
      </Box>
    </HStack>
  );
};

export default FlashSale;
