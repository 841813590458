import type { LandingProductCardProps } from './data';

import { ArrowLeft } from 'react-feather';

import {
  Box,
  Button,
  Image,
  Link,
  Stack,
  Tag,
  TagLabel,
  Text,
} from '@/components';
import { useTranslation } from '@/hooks';
import { routes } from '@/statics';
import { toToman } from '@/utils';

const LandingProductCard: React.FC<LandingProductCardProps> = ({
  cardData,
}) => {
  const { t } = useTranslation('pages.home');

  return (
    <Link href={routes.product.href(cardData?.code)}>
      <Box
        display="flex"
        role="group"
        gap="16px"
        justifyContent="space-between"
      >
        <Box position="relative" aspectRatio={1} alignSelf="flex-end">
          <Box
            position="relative"
            width={{ base: '121px', lg: '193px' }}
            height={{ base: '121px', lg: '193px' }}
            zIndex={'docked'}
          >
            <Image
              src={cardData.home_page_image}
              alt="image"
              fill
              style={{ borderRadius: '11px' }}
            />
          </Box>
          <Box
            borderRadius="md"
            boxShadow="0px 4px 11px 0px rgba(0, 0, 0, 0.25)"
            position="absolute"
            width={{ base: '121px', lg: '193px' }}
            bottom="0"
            left="50%"
            transform="translateX(-50%)"
            height={{ base: '80px', lg: '130px' }}
            aspectRatio={1}
            backgroundColor={cardData.color || 'gray.300'}
          ></Box>
          <Stack
            direction="column"
            position="absolute"
            right="0"
            bottom={3.5}
            gap="2"
            zIndex="docked"
          >
            {cardData.pricing &&
              cardData.pricing.discounts.length > 0 &&
              cardData.pricing.discounts.map(
                (discount) =>
                  discount.can_show && (
                    <Tag
                      key={discount.title}
                      size={{ base: 'sm', lg: 'md' }}
                      variant="promo"
                      bg={
                        discount.type === 'extra_retail_percent'
                          ? 'secondary.500'
                          : 'primary.500'
                      }
                    >
                      <TagLabel>{discount.percent}%</TagLabel>
                    </Tag>
                  )
              )}
          </Stack>
        </Box>
        <Box flexGrow="1" paddingTop={{ base: '40px', lg: '60px' }}>
          <Text
            fontSize={{ base: 'sm', xl: 'lg' }}
            fontWeight="bold"
            minHeight={{ base: ' 42px', xl: '56px' }}
            noOfLines={2}
            paddingInlineStart="8px"
          >
            {cardData.title}
          </Text>
          <Box
            display="flex"
            justifyContent="space-between"
            minH={{ base: '42px', xl: '48px' }}
            paddingInlineStart="8px"
          >
            <Text fontSize={{ base: 'xs', xl: 'lg' }} color="primary.700">
              {`${cardData.measurement_value} ${cardData.measurement_type === 'gr' ? t('gram') : t('mil')}`}
            </Text>
            <Box display="flex" flexDirection="column" alignItems="flex-end">
              {cardData.inventory.is_available ? (
                <>
                  <Text fontSize={{ base: 'sm', xl: 'lg' }}>
                    {toToman(
                      cardData.pricing
                        ? cardData.pricing.payable_price
                        : cardData.price
                    )}
                  </Text>
                  {cardData.pricing &&
                    cardData.pricing.payable_price !== cardData.price && (
                      <Text
                        fontSize={{ base: 'xs', lg: 'sm' }}
                        color="gray.700"
                        textDecoration="line-through"
                      >
                        {toToman(cardData.price)}
                      </Text>
                    )}
                </>
              ) : (
                <Text fontSize={{ base: 'xs', xl: 'sm' }} color="red.500">
                  {t('outOfStock')}
                </Text>
              )}
            </Box>
          </Box>
          <Button
            _groupHover={{ color: 'red.500', bgColor: 'transparent' }}
            variant="ghost"
            size="xs"
            rightIcon={<ArrowLeft width={12} />}
            fontSize={{ base: '12px', md: '16px' }}
            color="text"
            marginTop={2}
          >
            {t('viewProduct')}
          </Button>
        </Box>
      </Box>
    </Link>
  );
};
export default LandingProductCard;
