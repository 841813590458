import type { TabPanelItemProps } from './data';

import React from 'react';

import { Box, Divider, SimpleGrid, Stack, TabPanel } from '@/components';
import { LandingProductCard } from '@/components/ui';

const TabPanelItem: React.FC<TabPanelItemProps> = ({ products }) => {
  return (
    <TabPanel paddingInline={{ base: 4, md: 0 }}>
      <Box position="relative" marginTop={{ base: 5, md: 6 }} width="100%">
        <Divider
          position="absolute"
          bottom="0"
          left="50%"
          orientation="vertical"
          display={{ base: 'none', md: 'block' }}
        />
        <Stack flexGrow={1} direction={'row'} width={'100%'}>
          <SimpleGrid
            columns={{ base: 1, md: 2 }}
            spacing={{ base: 5, md: '60px' }}
            width="100%"
            flexDirection="row"
          >
            {products?.map((product) => (
              <LandingProductCard key={product.id} cardData={product} />
            ))}
          </SimpleGrid>
        </Stack>
      </Box>
    </TabPanel>
  );
};
export default TabPanelItem;
