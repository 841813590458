import type { IProductCategory } from '@/types';
import type { SwiperOptions } from 'swiper/types';

import React from 'react';
import { FreeMode } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';

import ViewAllProduct from './ViewAllProducts';

import { Box, Image, Link } from '@/components';
import { routes } from '@/statics';

const CategoriesSwiper: React.FC<{
  categories: IProductCategory[];
}> = ({ categories }) => {
  const settings: SwiperOptions = {
    freeMode: true,
    breakpoints: {
      576: {
        slidesPerView: 2.5,
      },
      992: {
        slidesPerView: 3.5,
      },
      1200: {
        slidesPerView: 4.5,
      },
    },
  };

  return (
    <Box
      my={{ base: 9, md: 16 }}
      sx={{
        '.swiper': {
          paddingBlock: '10px',
          marginBottom: '34px',
          paddingInline: '20px',
          '@media screen and (min-width: 1440px)': {
            paddingInline: 'calc((100% - 1440px) / 2)',
          },
        },
        '.swiper-slide': {
          width: 'calc(100% / 1.5)',
          paddingInline: '40px',
          '@media screen and (min-width: 576px)': {
            width: 'calc(100% / 2.5)',
          },
          '@media screen and (min-width: 992px)': {
            width: 'calc(100% / 3.5)',
          },
          '@media screen and (min-width: 1200px)': {
            width: 'calc(100% / 4.5)',
          },
        },
      }}
    >
      <Swiper
        watchSlidesProgress={true}
        {...settings}
        dir="rtl"
        modules={[FreeMode]}
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {categories?.map((category) => (
          <SwiperSlide key={category.id}>
            <Link
              href={routes.category.href(category.code)}
              key={category.id}
              display="block"
              aspectRatio={99 / 152}
              width="100%"
              position="relative"
              sx={{
                '.img': {
                  borderRadius: '444px',
                  objectFit: 'cover',
                },
              }}
            >
              <Image
                src={category.image || ''}
                alt={category.id}
                fill
                className="img"
                sizes="(max-width: 992px) 33vw, (max-width: 1200px) 20vw, 15vw"
                loading="eager"
              />
            </Link>
          </SwiperSlide>
        ))}
        <SwiperSlide>
          <ViewAllProduct sx={{ aspectRatio: 99 / 152 }} />
        </SwiperSlide>
      </Swiper>
    </Box>
  );
};
export default CategoriesSwiper;
