'use client';

import React from 'react';

import 'swiper/css';

import { Box, Container, Icon, Image, Link, Text } from '@/components';
import { useTranslation } from '@/hooks';
import useBrands from '@/hooks/queries/useBrands';
import { routes } from '@/statics';
import TitleBrands from '@/svgs/title_brands.svg';

const Brands: React.FC = () => {
  const { t } = useTranslation('pages.home');

  const { data: brands } = useBrands();

  if (!brands) {
    return null;
  }
  return (
    <Container mt={{ base: '50px', md: '76px' }} mb="76px" px={0}>
      <Text
        mb={{ base: 9, md: '80px' }}
        display="flex"
        alignItems="center"
        justifyContent="center"
        fontSize={{ base: 'md', md: 'xl' }}
        fontWeight={{ base: 'normal', md: 'bold' }}
      >
        <Icon
          as={TitleBrands}
          style={{ marginInlineEnd: '14px' }}
          width="28px"
          height="28px"
        />
        {t('brands')}
      </Text>

      <Box display="flex" overflow="hidden" justifyContent="center" px={2}>
        {brands?.map((brand) => (
          <Link
            key={brand.code}
            href={routes.brand.href(brand.code)}
            width="25%"
          >
            <Box
              display="block"
              width="100%"
              position="relative"
              height={{ base: '25px', sm: '50px', md: '80px' }}
              marginInline={{ base: '5px', md: '20px' }}
              sx={{
                img: {
                  objectFit: 'contain',
                  width: 'auto',
                },
              }}
            >
              <Image src={brand.image || ''} alt={brand.code} fill />
            </Box>
          </Link>
        ))}
      </Box>
    </Container>
  );
};
export default Brands;
