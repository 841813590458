'use client';

import type { ProductDiscount } from '@/types';

import dynamic from 'next/dynamic';

import TitleDynamic from '../TitleDynamic';

import { Box, Container, Flex, Image, Link } from '@/components';
import { useTranslation } from '@/hooks';
import { routes } from '@/statics';

const FlashSale = dynamic(() => import('../FlashSale'), {
  ssr: false,
});

const Promotion: React.FC<{ productDiscount: ProductDiscount[] }> = ({
  productDiscount,
}) => {
  const { t } = useTranslation('pages.home');
  return (
    <>
      <Box backgroundColor="primary.50">
        <Container>
          <Box paddingTop={{ base: '35px', md: '95px' }} paddingBottom="67px">
            <TitleDynamic color="white" title={t('newPromotionLia')} />
            <Box
              display="flex"
              gap={{ base: 14, md: 16 }}
              mt={8}
              flexWrap={{ base: 'wrap', md: 'nowrap' }}
            >
              {productDiscount.map((item) => (
                <Link
                  href={routes.promotions.href(item.code)}
                  key={item.id}
                  display="block"
                  sx={{ '& img': { objectFit: 'cover' } }}
                  width={{ base: '100%' }}
                  position="relative"
                >
                  {item.type === 'flash_sale' && (
                    <Flex
                      position="absolute"
                      w="full"
                      top={-6}
                      left={0}
                      justify="center"
                    >
                      <FlashSale endDate={item.end_at} />
                    </Flex>
                  )}
                  <Box aspectRatio={22 / 9} position="relative">
                    <Image alt="promotion" src={item.preview_image} fill />
                  </Box>
                </Link>
              ))}
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};
export default Promotion;
