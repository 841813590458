import { ArrowLeft } from 'react-feather';

import { Box, Button, Divider, Image, Link, Text } from '@/components';
import { useTranslation } from '@/hooks';
import { routes } from '@/statics';
import { type StaticImageData } from '@/types';
import { jalaliDayjs } from '@/utils';

const BlogPostCard: React.FC<{
  image: StaticImageData | string;
  title?: string;
  dateOfRelease?: string;
  studyTime?: number;
  direction?: 'column' | 'row';
  size?: 'small' | 'large';
  description?: string;
  sizes?: string;
  loading?: 'lazy' | 'eager';
  code: string;
  noOfLines?: number;
}> = ({
  image,
  title,
  dateOfRelease,
  studyTime,
  direction = 'column',
  size = 'small',
  description,
  sizes,
  loading = 'lazy',
  code,
  noOfLines = 2,
}) => {
  const { t } = useTranslation('components.blogs');
  const isLargeVertical = direction === 'column' && size === 'large';

  return (
    <Link href={routes.blog.href(code)} display="block" height="100%">
      <Box
        display="flex"
        flexDirection={direction}
        width="100%"
        borderRadius="lg"
        boxShadow="lg"
        paddingInline="13px"
        paddingBlock="13px"
        height="100%"
        backgroundColor="white"
        cursor="pointer"
      >
        <Box
          width={direction === 'row' ? '40%' : '100%'}
          position="relative"
          flexShrink={0}
          borderRadius="lg"
          overflow="hidden"
          aspectRatio={isLargeVertical ? 65 / 18 : 26 / 19}
          sx={{
            '& img': {
              borderRadius: 'lg',
              objectFit: 'cover',
            },
          }}
        >
          <Image
            alt={'title'}
            src={image}
            fill
            sizes={sizes}
            loading={loading}
          />
        </Box>
        <Box
          flexGrow={1}
          height="100%"
          display="flex"
          flexDirection="column"
          mr={direction === 'row' ? 4 : 0}
        >
          <Text lineHeight="33px" mt={direction === 'row' ? 0 : 5}>
            {title}
          </Text>
          {size === 'large' && (
            <Text
              mt={6}
              color="gray.700"
              lineHeight="33px"
              overflow="hidden"
              noOfLines={noOfLines}
            >
              {description}
            </Text>
          )}
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mt="auto"
            pt="34px"
            width="100%"
          >
            <Text fontSize="xs" fontWeight="bold" color="gray.800">
              {`${t('studyTime')} ${studyTime} ${t('min')}`}
            </Text>
            <Divider
              orientation="vertical"
              height="18px"
              display={
                direction === 'row' && size === 'small' ? 'block' : 'none'
              }
            />
            <Text fontSize="xs" fontWeight="bold" color="gray.800">
              {`${size === 'large' ? t('dateOfRelease') : ''}  ${jalaliDayjs(new Date(dateOfRelease!)).format('YYYY/MM/DD')}`}
            </Text>
            {direction === 'column' && size === 'large' && (
              <Button
                rightIcon={<ArrowLeft size="14" />}
                size="sm"
                variant="ghost"
              >
                {t('readMore')}
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </Link>
  );
};
export default BlogPostCard;
