'use client';

import type { TabHomePageProps } from './data';

import React from 'react';
import { Gift, Star } from 'react-feather';

import TabItem from './TabItem';
import TabPanelItem from './TabPanelItem';

import {
  Box,
  Container,
  TabIndicator,
  TabList,
  TabPanels,
  Tabs,
} from '@/components';
import { useTranslation } from '@/hooks';
import Fire from '@/svgs/fire.svg';

const TabHomePage: React.FC<TabHomePageProps> = ({
  bestSellingProductGroups,
  newestProductGroups,
  popularProductGroups,
}) => {
  const { t } = useTranslation('pages.home');

  const tabs = [
    { title: t('newest'), icon: Star, id: 1 },
    { title: t('best-selling'), icon: Gift, id: 2 },
    { title: t('popular'), icon: Fire, id: 3 },
  ];

  return (
    <Box
      display="flex"
      justifyContent="center"
      mt={{ base: 9, md: 10 }}
      mb={16}
      paddingInlineStart={{ base: 0, lg: '82px' }}
      paddingInlineEnd={{ base: 0, lg: '75px' }}
    >
      <Tabs variant="unstyled" width="100%">
        <TabList
          background={{ base: 'gray.100', md: 'unset' }}
          display="flex"
          justifyContent="center"
          gap={{ base: 0, md: 70 }}
        >
          {tabs.map((tab) => (
            <TabItem title={tab.title} icon={tab.icon} key={tab.id} />
          ))}
        </TabList>
        <TabIndicator
          display={{ base: 'inline-block', md: 'none' }}
          h="2px"
          mt="-1.5px"
          bg="primary.700"
          borderRadius="1px"
        />
        <Container mt={{ base: 0, md: 8 }}>
          <TabPanels>
            <TabPanelItem products={newestProductGroups} />
            <TabPanelItem products={bestSellingProductGroups} />
            <TabPanelItem products={popularProductGroups} />
          </TabPanels>
        </Container>
      </Tabs>
    </Box>
  );
};

export default TabHomePage;
