import type { News } from '@/types';
import type { SwiperOptions } from 'swiper/types';

import React from 'react';
import { ChevronLeft, ChevronRight } from 'react-feather';
import { FreeMode, Navigation } from 'swiper/modules';
import {
  Swiper,
  type SwiperClass,
  type SwiperRef,
  SwiperSlide,
} from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';

import {
  BlogPostCard,
  Button,
  Container,
  Grid,
  GridItem,
  Icon,
  IconButton,
  Link,
  Text,
} from '@/components';
import { useTranslation } from '@/hooks';
import { routes } from '@/statics';

const BlogDesktop: React.FC<{ blogs: News[] }> = ({ blogs }) => {
  const { t } = useTranslation('pages.home');

  const swiperRef = React.useRef<SwiperRef>(null);
  const [progress, setProgress] = React.useState(0);

  const settings: SwiperOptions = {
    freeMode: true,
    slidesPerView: 2,
    breakpoints: {
      992: {
        slidesPerView: 3,
      },
    },
    navigation: {
      prevEl: '.swiper-button-prev',
      nextEl: '.swiper-button-next',
    },
  };
  const prevSlide = () => {
    swiperRef.current?.swiper.slidePrev();
  };

  const nextSlide = () => {
    swiperRef.current?.swiper.slideNext();
  };
  const handleSlideChange = (event: SwiperClass) => {
    setProgress(event.progress);
  };
  return (
    <Container>
      <Grid templateColumns="repeat(4, 1fr)" gap={6}>
        <GridItem
          colSpan={3}
          sx={{
            '.swiper': {
              padding: '10px',
            },
            '.swiper-slide': {
              width: 'calc(100% / 2)',
              '@media screen and (min-width: 992px)': {
                width: 'calc(100% / 3)',
              },
              paddingInline: '20px',
            },
          }}
        >
          <Swiper
            ref={swiperRef}
            watchSlidesProgress={true}
            {...settings}
            dir="rtl"
            modules={[FreeMode, Navigation]}
            onSlideChange={handleSlideChange}
          >
            {blogs?.map((blog) => (
              <SwiperSlide key={blog.id} style={{ height: 'auto' }}>
                <BlogPostCard
                  sizes="15vw"
                  image={blog.preview_image}
                  title={blog.title}
                  dateOfRelease={blog.publish_at}
                  studyTime={blog.study_time}
                  loading="eager"
                  code={blog.code}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </GridItem>

        <GridItem
          colSpan={1}
          pt={{ base: '50px', lg: '67px' }}
          marginRight={{ base: 0, lg: '10px' }}
        >
          <IconButton
            isDisabled={progress === 0}
            backgroundColor="primary.200"
            aria-label="previous"
            icon={<Icon boxSize="6" as={ChevronRight} stroke={'text'} />}
            onClick={() => {
              prevSlide();
            }}
          />
          <IconButton
            isDisabled={progress === 1}
            backgroundColor="primary.200"
            aria-label="next"
            icon={<Icon boxSize="6" as={ChevronLeft} stroke={'text'} />}
            marginInlineStart="16px"
            onClick={() => {
              nextSlide();
            }}
          />
          <Text fontSize="lg" fontWeight="bold" mt={8}>
            {t.rich('followTheLatestNews', { br: () => <br /> })}
          </Text>
          <Button
            mt={8}
            variant="outline"
            colorScheme="gray"
            as={Link}
            href={routes.blogs.url}
          >
            {t('viewAllBlogs')}
          </Button>
        </GridItem>
      </Grid>
    </Container>
  );
};
export default BlogDesktop;
