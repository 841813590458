import type { IImage } from './data';

import React from 'react';
import { default as NextImage } from 'next/image';

const Image: IImage = ({ quality: _quality, ...props }) => {
  const isGif = JSON.stringify(props.src).includes('.gif');
  return <NextImage quality={90} unoptimized={isGif} {...props} />;
};

export default Image;
