import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
import jalaliday from 'jalali-plugin-dayjs';

// add jalali prop to format
type jalaliDayjsType = (
  date?: dayjs.ConfigType,
  format?: {
    locale?: string;
    format?: string;
    utc?: boolean;
    jalali?: boolean;
  },
  locale?: string,
  strict?: boolean
) => dayjs.Dayjs;

const jalaliDayjs: jalaliDayjsType = (date, config = {}, ...params) =>
  dayjs(date, { jalali: true, ...config }, ...params);

export const setDayjsJalaliCalendar = (): void => {
  dayjs.extend(calendar);
  dayjs.extend(jalaliday).calendar('jalali');
  dayjs.locale('fa');
};
export default jalaliDayjs;
