'use client';

import CategoriesMobile from './CategoriesMobile';
import CategoriesSwiper from './CategoriesSwiper';

import { Box, Container } from '@/components';
import { useCategories } from '@/hooks';
import Background from '@/images/layout/backgroundCategory.svg';

const Categories: React.FC = () => {
  const { data: categoriesData } = useCategories();
  const rootCategories = categoriesData?.filter(
    (item) => item.parent_code === null
  );
  return (
    <>
      <Container display={{ base: 'block', md: 'none' }}>
        <CategoriesMobile categories={rootCategories || []} />
      </Container>
      <Box display={{ base: 'none', md: 'block' }} position="relative">
        <Box position="absolute" top="-180px" left="0" right="0">
          <Background width="100%" />
        </Box>
        <CategoriesSwiper categories={rootCategories || []} />
      </Box>
    </>
  );
};
export default Categories;
