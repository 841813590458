import { Grid } from 'react-feather';

import { Box, type BoxProps, Button, Icon, Link, Text } from '@/components';
import { useTranslation } from '@/hooks';
import { routes } from '@/statics';

const ViewAllProduct: React.FC<BoxProps> = ({ ...props }) => {
  const { t } = useTranslation('pages.home');
  return (
    <>
      <Box
        height="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        {...props}
      >
        <Box>
          <Box display="flex" alignItems="flex-start">
            <Icon as={Grid} width="30px" height="30px" mt={1} />
            <Text fontSize="xl" fontWeight="bold" paddingInlineStart={5}>
              {t.rich('liaProductCategories', { br: () => <br /> })}
            </Text>
          </Box>
          <Button
            as={Link}
            href={routes.categories.url}
            variant="outline"
            colorScheme="gray"
            mt={7}
            size="sm"
          >
            {t('viewAllProducts')}
          </Button>
        </Box>
      </Box>
    </>
  );
};
export default ViewAllProduct;
