'use client';

import type { News } from '@/types';

import BlogDesktop from './BlogDesktop';
import BlogMobile from './BlogMobile';

import { Box, Icon, Text } from '@/components';
import { useTranslation } from '@/hooks';
import Note from '@/svgs/note.svg';

const Blog: React.FC<{ news: News[] }> = ({ news }) => {
  const { t } = useTranslation('pages.home');

  return (
    <>
      <Text
        mb={{ base: 7, md: 14 }}
        display="flex"
        alignItems="center"
        justifyContent="center"
        fontSize={{ base: 'md', md: 'xl' }}
        fontWeight={{ base: 'normal', md: 'bold' }}
      >
        <Icon
          as={Note}
          style={{ marginInlineEnd: '14px' }}
          width={{ base: '18px', md: '24px' }}
          height={{ base: '22px', md: '28px' }}
        />
        {t('latestBlog')}
      </Text>
      <Box display={{ base: 'block', md: 'none' }}>
        <BlogMobile blogs={news} />
      </Box>
      <Box display={{ base: 'none', md: 'block' }}>
        <BlogDesktop blogs={news} />
      </Box>
    </>
  );
};
export default Blog;
