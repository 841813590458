import type { LinkProps } from '@/types';

import { Link } from '@/components';
import { useTranslation } from '@/hooks';
import { routes } from '@/statics';

const ViewAllProducts: React.FC<LinkProps> = ({ ...props }) => {
  const { t } = useTranslation('pages.home');
  return (
    <Link
      href={routes.categories.url}
      width="100%"
      height="100%"
      border="1px solid black"
      borderRadius="444px"
      display="flex"
      justifyContent="center"
      alignItems="center"
      lineHeight="35px"
      paddingInline="30px"
      textAlign="center"
      fontWeight="bold"
      cursor="pointer"
      color="gray.800"
      {...props}
    >
      {t('viewAllProducts')}
    </Link>
  );
};
export default ViewAllProducts;
