import type { IProductCategory } from '@/types';

import { Grid } from 'react-feather';

import ViewAllProducts from './ViewAllProducts';

import { Box, Icon, Image, Link, SimpleGrid, Text } from '@/components';
import { useTranslation } from '@/hooks';
import { routes } from '@/statics';

const CategoriesMobile: React.FC<{
  categories: IProductCategory[];
}> = ({ categories }) => {
  const { t } = useTranslation('pages.home');
  return (
    <Box mb={9} mt={9} px={4}>
      <Box display="flex" justifyContent="center" alignItems="center" mb={6}>
        <Icon as={Grid} width="15px" height="15px" />
        <Text marginInlineStart={3} fontSize="md">
          {t('LiaProductCategories')}
        </Text>
      </Box>
      <SimpleGrid columns={3} spacing={6} justifyContent="center">
        {categories?.map((category) => (
          <Link
            href={routes.category.href(category.code)}
            key={category.id}
            display="block"
            aspectRatio={99 / 152}
            width="100%"
            position="relative"
            sx={{
              '.img': {
                borderRadius: '444px',
                objectFit: 'cover',
              },
            }}
          >
            <Image
              src={category.image || ''}
              alt={category.id}
              fill
              sizes="50vw"
              className="img"
            />
          </Link>
        ))}
        <ViewAllProducts
          sx={{
            aspectRatio: 99 / 152,
            fontSize: 'sm',
            lineHeight: '38px',
            fontWeight: 'medium',
            padding: 1.5,
          }}
        />
      </SimpleGrid>
    </Box>
  );
};
export default CategoriesMobile;
