import { Grid } from 'react-feather';
import parse from 'html-react-parser';

import { Box, Icon, Text } from '@/components';

const TitleDynamic: React.FC<{ title: string; color?: string }> = ({
  title,
  color = 'gray.300',
}) => {
  const replaceWord: Record<string, string> = {
    لیا: '<span style="color:#A2224B">لیا</span>',
    لیاتیم: '<span style="color:#A2224B">لیاتیم</span>',
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      position="relative"
    >
      <Box
        width="75px"
        height="75px"
        borderRadius="50%"
        background={color}
        position="absolute"
        left="50%"
        top="50%"
        transform="translate(-50%,-50%)"
        display={{ base: 'none', md: 'block' }}
      ></Box>
      <Icon
        as={Grid}
        width={{ base: '15px', md: '21px' }}
        height={{ base: '15px', md: '21px' }}
      />
      <Text
        fontSize={{ base: 'md', md: '2xl' }}
        zIndex={'docked'}
        fontWeight="bold"
        paddingInlineStart={4}
      >
        {parse(title.replace(/لیاتیم|لیا/gi, (word) => replaceWord[word]))}
      </Text>
    </Box>
  );
};
export default TitleDynamic;
