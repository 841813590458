import './fashion-slider.scss';

const SliderSVG: React.FC = () => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 350 160 90">
        <g className="fashion-slider-svg-wrap">
          <g className="fashion-slider-svg-circle-wrap">
            <circle cx="42" cy="42" r="40"></circle>
          </g>
          <path className="fashion-slider-svg-line" d="M80,0H0"></path>
          <path
            className="fashion-slider-svg-arrow"
            d="M.983,6.929,4.447,3.464.983,0,0,.983,2.482,3.464,0,5.946Z"
          ></path>
        </g>
      </svg>
    </>
  );
};

export default SliderSVG;
