'use client';

import type { PromotionBanner } from '@/types';

import { Box, Container, Image, Link, SimpleGrid } from '@/components';

const CallToAction: React.FC<{ promotionBanners: PromotionBanner[] }> = ({
  promotionBanners,
}) => {
  return (
    <Container>
      <SimpleGrid
        columns={{ base: 1, md: 2 }}
        spacing={{ base: 4, md: 5 }}
        justifyContent="center"
      >
        {promotionBanners.map((data) => (
          <Box display="flex" justifyContent="center" key={data.id}>
            <Box
              width="100%"
              maxWidth={{ base: '390px', md: '100%' }}
              height="auto"
            >
              <Link
                href={data.link}
                target="_blank"
                prefetch={false}
                sx={{
                  '& img': {
                    objectFit: 'cover',
                  },
                }}
              >
                <Box
                  display={{ base: 'none', md: 'block' }}
                  position="relative"
                  aspectRatio={332 / 230}
                >
                  <Image
                    src={data.landscape_image}
                    alt={data.alt_image}
                    fill
                    sizes="100vw"
                  />
                </Box>
                <Box
                  display={{ base: 'block', md: 'none' }}
                  position="relative"
                  aspectRatio={332 / 230}
                >
                  <Image
                    src={data.portrait_image}
                    alt={data.alt_image}
                    fill
                    sizes="100vw"
                  />
                </Box>
              </Link>
            </Box>
          </Box>
        ))}
      </SimpleGrid>
    </Container>
  );
};
export default CallToAction;
