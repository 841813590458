'use client';

import type { Slider } from '@/types';

import React from 'react';
import { Autoplay } from 'swiper/modules';
import { Swiper, type SwiperRef, SwiperSlide } from 'swiper/react';

import SliderSVG from './SliderSVG';

import './fashion-slider.scss';

import { Box, Image, Link } from '@/components';

const LandingSlider: React.FC<{ sliders: Slider[] }> = ({ sliders }) => {
  const sliderRef = React.useRef<SwiperRef>(null);
  const [currentSlide, setCurrentSlide] = React.useState(0);

  const onPrevClick = () => {
    sliderRef.current?.swiper.slidePrev();
  };

  const onNextClick = () => {
    sliderRef.current?.swiper.slideNext();
  };

  return (
    <>
      <Box className="container">
        <Box className="fashion-slider">
          <Swiper
            ref={sliderRef}
            dir="rtl"
            modules={[Autoplay]}
            autoplay={true}
            speed={500}
            onSlideChange={(swiper) => setCurrentSlide(swiper.activeIndex)}
            className="swiper"
          >
            {sliders?.map((slide, index) => (
              <SwiperSlide key={slide.id}>
                <Link
                  href={slide.link}
                  target="_blank"
                  position="relative"
                  aspectRatio={{ base: 20 / 21, md: 12 / 5 }}
                  width="full"
                  height="auto"
                  display="block"
                >
                  <Box display={{ base: 'none', md: 'block' }}>
                    <Image
                      alt={slide.alt_image}
                      src={slide.image}
                      width={2000}
                      height={834}
                      style={{ minWidth: '100%' }}
                      loading={index === 0 ? 'eager' : 'lazy'}
                    />
                  </Box>
                  <Box display={{ base: 'block', md: 'none' }}>
                    <Image
                      alt={slide.alt_image}
                      src={slide.portrait_image}
                      style={{ minWidth: '100%' }}
                      width={400}
                      height={420}
                      loading={index === 0 ? 'eager' : 'lazy'}
                    />
                  </Box>
                </Link>
              </SwiperSlide>
            ))}
            <Box
              className={`fashion-slider-button-next fashion-slider-button ${currentSlide === sliders.length - 1 ? 'fashion-slider-button-disabled' : ''}`}
              onClick={() => onNextClick()}
            >
              <SliderSVG />
            </Box>
            <Box
              className={`fashion-slider-button-prev fashion-slider-button ${currentSlide === 0 ? 'fashion-slider-button-disabled' : ''}`}
              onClick={onPrevClick}
            >
              <SliderSVG />
            </Box>
          </Swiper>
        </Box>
      </Box>
    </>
  );
};
export default LandingSlider;
