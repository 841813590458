import type { IIconButton } from './ types';

import { Button as CkButton } from '@chakra-ui/react';

const IconButton: IIconButton = ({ icon, ...props }) => {
  return (
    <CkButton
      w={{ base: '30px', md: 8 }}
      minW="unset"
      h={{ base: '30px', md: 8 }}
      p={0}
      lineHeight="normal"
      borderRadius="50%"
      {...props}
    >
      {icon}
    </CkButton>
  );
};

export default IconButton;
